import logo from './assets/logo.svg';
import arrow from './assets/fi_Arrow.svg';
import menu from './assets/fi_menu.svg';
import cross from './assets/fi_x.svg';
import volumeMute from './assets/fi_volume-x.svg';
import volumeUp from './assets/fi_volume-2.svg';
import videoSkip from './assets/fi_skip-forward.svg';
import videoReplay from './assets/fi_history.svg'
import whatsapp from './assets/fi_whatsapp.svg'
import mail from './assets/fi_mail.svg'
import chat from './assets/fi_message-circle.svg'
import * as animationOrientation from "./assets/screen-orientation.json";
// import arc2 from './assets/arc-2.svg';
// import round3 from './assets/round-3.svg';
// import './App.css';
import './Site.css';
import {useEffect, useRef, useState} from "react";
import Lottie from "lottie-react";

function App() {
  const [screenOrientation, setScreenOrientation] = useState("portrait");
  const [showIntro, setShowIntro] = useState(true)
  const [showPage, setShowPage] = useState(null)
  const [video, setVideo] = useState('video1')
  const [videoLoop, setVideoLoop] = useState(false)
  const [videoMuted, setVideoMuted] = useState(false)
  const [menuActive, setMenuActive] = useState(false)
  const videoRef = useRef()

  let mql = window.matchMedia("(orientation: portrait)");

  mql.addEventListener("change", function (m) {
    if (m.matches) {
      setScreenOrientation("portrait");
    } else {
      setScreenOrientation("landscape");
    }
  });

  useEffect(() => {
    // changeScreenOrientation();
    if (mql.matches) {
      setScreenOrientation("portrait");
    } else {
      setScreenOrientation("landscape");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTimeUpdate = (time) => {
    // console.log(time.target.currentTime)
  };

  const handleEnded = () => {
    if (video === 'video1') {
      handleSkip()
    }
  }

  const handleMenu = () => {
    setMenuActive(!menuActive)
  }

  const handleReplay = () => {
    setVideo('video1');
    videoRef.current.currentTime = 0;
  }

  const handleSkip = () => {
    setVideo('video2')
    setVideoLoop(true)
    setTimeout(() => {
      videoRef.current.load();
    }, 1);
  }

  const handleChangePage = (page) => {
    setShowPage(page)
    setMenuActive(false)
  }

  if (screenOrientation?.includes("portrait")) {
    return (<div className="screenOrientation">
      <Lottie animationData={animationOrientation}/>
    </div>)
  }

  if (showIntro) {
    return (
      <div className="bgIntro">
        <div className="fullContent">
          <div className="content">
            {/*<img src={logoBaseline} alt={"Digiplay"} className="logoBaseline" />*/}
            <svg width="606" height="317" viewBox="0 0 606 317" fill="none" xmlns="http://www.w3.org/2000/svg"
                 className="logoBaseline">
              <g stroke="#D5E5F2" fill="#D5E5F2" clipPath="url(#clip0_595_5753)">
                <path
                  d="M87.7696 313.268C86.461 313.935 84.9329 314.26 83.1413 314.26C81.701 314.26 80.3574 313.988 79.1366 313.417C77.8983 312.855 76.8357 312.091 75.9311 311.134C75.0002 310.15 74.2976 309.017 73.7883 307.717C73.2789 306.391 73.0242 304.977 73.0242 303.467V303.405C73.0242 301.904 73.2789 300.498 73.7883 299.199C74.2976 297.881 75.0002 296.757 75.9311 295.782C76.8357 294.808 77.8896 294.043 79.1366 293.508C80.3574 292.963 81.701 292.691 83.1413 292.691C84.9329 292.691 86.4698 293.034 87.752 293.701C89.0254 294.368 90.2374 295.22 91.3527 296.265L93.3726 294.105C92.7315 293.49 92.0553 292.911 91.3527 292.41C90.6501 291.892 89.9036 291.47 89.0781 291.101C88.2702 290.75 87.3656 290.452 86.4171 290.267C85.4598 290.074 84.3884 289.969 83.1765 289.969C81.2619 289.969 79.4967 290.329 77.8808 291.031C76.2561 291.743 74.8509 292.709 73.7004 293.947C72.5236 295.159 71.6103 296.625 70.9692 298.241C70.3017 299.892 69.9768 301.64 69.9768 303.476V303.546C69.9768 305.425 70.3017 307.182 70.9692 308.815C71.6103 310.466 72.5324 311.898 73.7004 313.101C74.8509 314.295 76.2561 315.252 77.8369 315.955C79.4264 316.666 81.1741 317.018 83.0799 317.018C84.2655 317.018 85.3457 316.886 86.3205 316.675C87.2865 316.464 88.1999 316.139 89.0518 315.744C89.8861 315.34 90.6765 314.866 91.4142 314.295C92.1519 313.742 92.8457 313.118 93.5307 312.425L91.5723 310.528C90.334 311.704 89.0518 312.644 87.7696 313.294V313.268Z"/>
                <path
                  d="M118.832 304.968C119.693 304.573 120.448 304.046 121.107 303.414C121.73 302.79 122.222 302.026 122.582 301.175C122.925 300.323 123.092 299.339 123.092 298.233V298.171C123.092 297.117 122.925 296.151 122.582 295.299C122.222 294.43 121.73 293.657 121.081 293.025C120.264 292.19 119.228 291.541 117.963 291.093C116.707 290.627 115.275 290.408 113.651 290.408H102.41V316.535H105.369V306.382H112.834L120.413 316.535H124.049L116.013 305.847C117.041 305.662 117.989 305.399 118.841 304.96L118.832 304.968ZM105.36 303.739V293.148H113.449C115.565 293.148 117.208 293.596 118.384 294.509C119.552 295.414 120.132 296.687 120.132 298.303V298.364C120.132 299.216 119.965 299.954 119.631 300.613C119.298 301.271 118.832 301.833 118.226 302.299C117.629 302.747 116.918 303.107 116.092 303.379C115.267 303.607 114.371 303.739 113.405 303.739H105.352H105.36Z"/>
                <path
                  d="M136.081 304.705H150.325V302.053H136.081V293.051H152.02V290.399H133.13V316.535H152.205V313.891H136.081V304.705Z"/>
                <path
                  d="M171.543 290.214L159.626 316.535H162.638L165.747 309.623H179.965L183.039 316.535H186.218L174.301 290.214H171.543ZM166.924 306.971L172.896 293.666L178.85 306.971H166.924Z"/>
                <path d="M193.235 293.139H202.018V316.535H204.951V293.139H213.733V290.399H193.235V293.139Z"/>
                <path d="M226.256 290.399H223.297V316.535H226.256V290.399Z"/>
                <path
                  d="M247.966 312.916L238.683 290.408H235.381L246.614 316.737H249.231L260.481 290.408H257.302L247.966 312.916Z"/>
                <path
                  d="M272.319 304.705H286.564V302.053H272.319V293.051H288.268V290.399H269.36V316.535H288.452V313.891H272.319V304.705Z"/>
                <path
                  d="M329.694 313.268C328.394 313.935 326.857 314.26 325.057 314.26C323.625 314.26 322.29 313.988 321.052 313.417C319.814 312.855 318.76 312.091 317.846 311.134C316.942 310.15 316.222 309.017 315.739 307.717C315.212 306.391 314.957 304.977 314.957 303.467V303.405C314.957 301.904 315.22 300.498 315.739 299.199C316.222 297.881 316.942 296.757 317.846 295.782C318.76 294.808 319.814 294.043 321.052 293.508C322.29 292.963 323.625 292.691 325.057 292.691C326.866 292.691 328.394 293.034 329.676 293.701C330.958 294.368 332.153 295.22 333.285 296.265L335.297 294.105C334.647 293.49 333.979 292.911 333.285 292.41C332.583 291.892 331.81 291.47 330.993 291.101C330.168 290.75 329.29 290.452 328.332 290.267C327.375 290.074 326.304 289.969 325.118 289.969C323.177 289.969 321.412 290.329 319.77 291.031C318.18 291.743 316.775 292.709 315.607 293.947C314.43 295.159 313.525 296.625 312.867 298.241C312.208 299.892 311.883 301.64 311.883 303.476V303.546C311.883 305.425 312.217 307.182 312.867 308.815C313.525 310.466 314.43 311.898 315.607 313.101C316.775 314.295 318.154 315.252 319.761 315.955C321.368 316.666 323.081 317.018 324.969 317.018C326.172 317.018 327.261 316.886 328.227 316.675C329.211 316.464 330.106 316.139 330.958 315.744C331.801 315.34 332.6 314.866 333.321 314.295C334.058 313.742 334.743 313.118 335.428 312.425L333.487 310.528C332.24 311.704 330.967 312.644 329.676 313.294L329.694 313.268Z"/>
                <path
                  d="M366.175 293.973C364.989 292.744 363.602 291.751 361.968 291.04C360.326 290.32 358.508 289.96 356.532 289.96C354.556 289.96 352.712 290.32 351.078 291.049C349.427 291.786 348.022 292.788 346.854 294C345.686 295.238 344.781 296.669 344.14 298.338C343.482 299.963 343.157 301.684 343.157 303.458V303.537C343.157 305.337 343.482 307.041 344.105 308.692C344.755 310.317 345.651 311.748 346.828 312.969C347.978 314.199 349.383 315.182 351.026 315.902C352.65 316.622 354.459 316.991 356.471 316.991C358.482 316.991 360.273 316.605 361.907 315.876C363.558 315.138 364.954 314.163 366.122 312.925C367.282 311.687 368.186 310.264 368.845 308.622C369.495 306.971 369.811 305.267 369.811 303.458C369.846 303.44 369.846 303.414 369.811 303.396C369.811 301.596 369.495 299.892 368.862 298.25C368.23 296.625 367.325 295.194 366.166 293.964L366.175 293.973ZM366.772 303.537C366.772 305.048 366.509 306.444 365.999 307.735C365.49 309.035 364.787 310.141 363.9 311.125C362.996 312.091 361.924 312.864 360.668 313.417C359.404 313.979 358.016 314.251 356.541 314.251C355.065 314.251 353.66 313.979 352.404 313.408C351.149 312.828 350.068 312.056 349.137 311.081C348.224 310.115 347.513 308.964 347.003 307.673C346.485 306.356 346.23 304.968 346.23 303.458V303.396C346.23 301.904 346.485 300.49 347.003 299.207C347.504 297.916 348.215 296.784 349.102 295.809C349.989 294.834 351.07 294.07 352.317 293.508C353.581 292.955 354.969 292.674 356.471 292.674C357.972 292.674 359.334 292.963 360.581 293.534C361.828 294.105 362.934 294.887 363.83 295.861C364.752 296.819 365.472 297.952 365.99 299.278C366.5 300.569 366.763 301.974 366.763 303.458V303.537H366.772Z"/>
                <path
                  d="M392.32 304.67L382.773 290.399H379.787V316.535H382.668V295.422L392.188 309.36H392.337L401.857 295.387V316.535H404.808V290.399H401.822L392.32 304.67Z"/>
                <path
                  d="M432.832 292.621C431.98 291.927 430.935 291.391 429.741 290.97C428.537 290.601 427.194 290.399 425.718 290.399H415.926V316.535H418.877V307.322H425.244C426.623 307.322 427.94 307.138 429.196 306.769C430.417 306.409 431.532 305.873 432.446 305.144C433.385 304.415 434.132 303.528 434.659 302.474C435.212 301.412 435.493 300.173 435.493 298.76V298.698C435.493 297.389 435.265 296.221 434.79 295.203C434.334 294.184 433.684 293.315 432.823 292.612L432.832 292.621ZM432.551 298.891C432.551 299.769 432.384 300.56 432.05 301.254C431.699 301.974 431.225 302.562 430.593 303.08C429.943 303.572 429.205 303.976 428.318 304.257C427.413 304.529 426.447 304.679 425.376 304.679H418.886V293.139H425.525C427.651 293.139 429.354 293.631 430.619 294.57C431.91 295.545 432.56 296.968 432.56 298.83V298.9L432.551 298.891Z"/>
                <path
                  d="M454.278 290.214L442.37 316.535H445.399L448.482 309.623H462.718L465.774 316.535H468.953L457.045 290.214H454.287H454.278ZM449.641 306.971L455.622 293.666L461.567 306.971H449.641Z"/>
                <path
                  d="M497.048 311.353L480.599 290.408H477.832V316.552H480.704V295.124L497.575 316.552H499.928V290.408H497.048V311.353Z"/>
                <path
                  d="M520.725 303.467L511.942 290.408H508.368L519.214 306.189V316.535H522.174V306.163L533.028 290.408H529.603L520.725 303.467Z"/>
                <path
                  d="M77.995 219.615V219.387C77.995 213.441 76.9674 207.979 74.886 203.008C72.8047 198.055 69.8011 193.752 65.8579 190.16C61.9059 186.576 57.0581 183.766 51.3146 181.773C45.5622 179.788 39.0107 178.787 31.6511 178.787H0V260.917H31.2033C38.5452 260.917 45.1319 259.881 50.9633 257.8C56.7947 255.736 61.7039 252.864 65.6911 249.176C69.6782 245.513 72.7256 241.149 74.8421 236.099C76.9499 231.058 78.0037 225.56 78.0037 219.615H77.995ZM54.7747 219.966C54.7747 226.605 52.6846 231.743 48.5306 235.335C44.3766 238.927 38.8877 240.727 32.0551 240.727H22.7547V198.959H32.0551C38.8877 198.959 44.3854 200.786 48.5306 204.413C52.6846 208.049 54.7747 213.16 54.7747 219.738V219.966Z"/>
                <path d="M118.042 178.778H95.1642V260.909H118.042V178.778Z"/>
                <path
                  d="M177.805 229.574H193.991V239.559C190.548 241.834 186.157 242.958 180.817 242.958C177.515 242.958 174.512 242.387 171.815 241.254C169.102 240.121 166.757 238.549 164.746 236.556C162.761 234.562 161.207 232.165 160.109 229.345C159.002 226.518 158.449 223.479 158.449 220.195V219.957C158.449 216.831 158.993 213.898 160.1 211.158C161.198 208.418 162.673 206.029 164.553 203.991C166.441 201.972 168.645 200.356 171.183 199.188C173.73 198.02 176.479 197.422 179.456 197.422C183.513 197.422 187.088 198.099 190.179 199.425C193.27 200.751 196.309 202.674 199.269 205.168L212.644 189.088C208.262 185.347 203.431 182.414 198.153 180.297C192.875 178.19 186.596 177.136 179.333 177.136C172.992 177.136 167.108 178.225 161.69 180.411C156.253 182.607 151.608 185.637 147.708 189.519C143.809 193.383 140.762 197.923 138.54 203.122C136.335 208.33 135.22 213.898 135.22 219.843V220.072C135.22 226.333 136.353 232.068 138.627 237.258C140.893 242.475 144.011 246.936 148.007 250.695C152.003 254.454 156.701 257.361 162.137 259.433C167.574 261.506 173.51 262.533 179.904 262.533C187.413 262.533 194.14 261.339 200.085 258.906C206.022 256.491 211.195 253.47 215.569 249.869V212.923H177.805V229.565V229.574Z"/>
                <path d="M256.564 178.778H233.686V260.909H256.564V178.778Z"/>
                <path
                  d="M345.879 207.882V207.645C345.879 203.104 345.08 199.038 343.473 195.429C341.866 191.837 339.582 188.807 336.614 186.339C333.637 183.872 330.045 182.01 325.829 180.71C321.605 179.41 316.907 178.778 311.734 178.778H276.552V260.909H299.307V237.443H310.575C315.66 237.443 320.358 236.819 324.705 235.563C329.044 234.325 332.776 232.446 335.903 229.943C339.029 227.44 341.471 224.348 343.245 220.686C344.992 217.007 345.879 212.747 345.879 207.891V207.882ZM323.124 208.892C323.124 212.194 321.965 214.793 319.673 216.734C317.355 218.658 314.175 219.615 310.109 219.615H299.316V197.783H309.995C314.131 197.783 317.363 198.705 319.673 200.566C321.974 202.411 323.124 205.107 323.124 208.655V208.892Z"/>
                <path d="M422.996 240.973H383.23V178.778H360.466V260.917H422.996V240.973Z"/>
                <path
                  d="M460.918 246.251H492.595L498.585 260.908H522.982L488.02 178.19H466.099L431.155 260.908H455.077L460.935 246.251H460.918ZM476.875 205.3L486.026 228.652H467.61L476.875 205.3Z"/>
                <path
                  d="M553.965 208.707L537.086 178.787H511.161L542.364 230.171V260.909H565.233V229.82L596.41 178.787H570.968L553.965 208.707Z"/>
                <path
                  d="M136.52 128.368C134.921 125.909 127.509 125.541 116.786 125.795C106.072 126.05 96.5518 127.49 92.1782 132.742C87.8135 137.994 95.6911 142.473 102.524 144.001C109.734 145.617 126.332 141.12 129.301 139.996C140.7 135.658 136.52 128.368 136.52 128.368Z"/>
                <path
                  d="M143.133 110.137C143.133 110.137 148.824 112.745 148.455 104.209C147.963 93.038 147.366 88.9982 138.303 73.9807C129.239 58.9545 113.466 39.827 104.825 40.5823C96.2093 41.3375 96.5606 55.2221 104.948 70.3888C113.326 85.5731 127.658 102.567 143.133 110.137Z"/>
                <path
                  d="M174.547 106.325C174.547 106.325 175.223 111.603 180.967 107.177C186.789 102.707 192.515 96.6474 195.071 92.5462C197.618 88.4361 199.822 70.424 201.991 53.3339C204.213 35.8487 206.936 -2.04624 199.111 0.0878227C191.303 2.20432 179.974 42.892 176.092 55.9247C172.202 68.9573 171.42 73.0322 171.956 85.4326C172.492 97.8067 173.888 104.806 174.538 106.325H174.547Z"/>
                <path
                  d="M199.954 118.497C198.531 121.334 210.431 124.241 210.431 124.241C223.56 127.692 232.922 125.242 244.919 121.246C247.755 120.298 274.515 110.189 283.973 105.535C294.055 100.573 301.371 96.4366 297.155 94.6802C288.935 91.2288 251.743 93.2926 233.844 98.1228C215.946 103.006 201.447 115.573 199.963 118.497H199.954Z"/>
                <path
                  d="M604.551 138.995C603.602 136.773 602.303 134.823 600.643 133.146C598.983 131.469 597.051 130.142 594.785 129.176C592.528 128.202 590.095 127.719 587.522 127.719C584.949 127.719 582.508 128.21 580.242 129.203C577.985 130.186 576.009 131.53 574.34 133.225C572.671 134.911 571.345 136.87 570.397 139.1C569.44 141.34 568.974 143.711 568.974 146.24V146.337C568.974 148.875 569.448 151.246 570.397 153.477C571.345 155.716 572.663 157.657 574.314 159.343C575.965 161.003 577.906 162.329 580.154 163.304C582.411 164.279 584.835 164.762 587.417 164.762C589.999 164.762 592.423 164.27 594.715 163.269C596.981 162.285 598.948 160.941 600.617 159.264C602.294 157.569 603.602 155.619 604.551 153.371C605.508 151.141 605.991 148.769 605.991 146.24V146.135C605.991 143.605 605.508 141.234 604.551 138.995ZM604.305 146.24C604.305 148.567 603.875 150.737 603.014 152.8C602.162 154.829 600.985 156.629 599.466 158.166C597.947 159.694 596.146 160.924 594.091 161.82C592.027 162.724 589.814 163.172 587.426 163.172C585.037 163.172 582.824 162.724 580.777 161.837C578.731 160.959 576.966 159.747 575.455 158.245C573.954 156.726 572.777 154.934 571.934 152.888C571.073 150.824 570.652 148.664 570.652 146.328V146.231C570.652 143.904 571.073 141.735 571.934 139.689C572.777 137.642 573.98 135.842 575.473 134.314C576.992 132.768 578.775 131.548 580.83 130.652C582.868 129.739 585.107 129.291 587.513 129.291C589.92 129.291 592.124 129.738 594.162 130.617C596.225 131.512 597.991 132.698 599.492 134.235C600.985 135.745 602.162 137.528 603.005 139.566C603.866 141.621 604.296 143.79 604.296 146.117V146.223L604.305 146.24Z"/>
                <path
                  d="M594.469 145.678C595.391 144.756 595.848 143.544 595.848 141.99V141.902C595.848 141.111 595.734 140.409 595.453 139.785C595.18 139.179 594.794 138.644 594.328 138.204C593.705 137.607 592.914 137.107 591.948 136.755C590.965 136.395 589.902 136.202 588.699 136.202H580.198V155.189H583.008V148.04H587.873L593.354 155.189H596.823L590.956 147.575C592.379 147.233 593.556 146.609 594.469 145.678ZM583.026 145.529V138.775H588.602C589.964 138.775 591.035 139.065 591.799 139.662C592.572 140.259 592.976 141.059 592.976 142.042V142.148C592.976 143.228 592.537 144.062 591.703 144.659C590.868 145.221 589.779 145.529 588.462 145.529H583.034H583.026Z"/>
              </g>
              <defs>
                <clipPath id="clip0_595_5753">
                  <rect width="606" height="317" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            <button className={"cta"} onClick={() => setShowIntro(false)}>
              Activez votre son et go !!!
              <img src={arrow} alt={"arrow"}/>
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="">
      <div className="bgPage"></div>
      <img src={logo} alt="Logo Digiplay" className="logo"/>
      <div className="leftContainer">
        <button className="leftBtn" onClick={() => setVideoMuted(!videoMuted)}><img src={videoMuted ? volumeUp : volumeMute} alt={'mute'} /></button>
        <button className="leftBtn" onClick={handleReplay}><img src={videoReplay} alt={'mute'} /></button>
        {video === 'video1' && <button className="leftBtn" onClick={handleSkip}><img src={videoSkip} alt={'skip'}/></button>}
      </div>
      <div className="rightContainer">
        <button className="rightBtn whatsapp" onClick={() => console.log('go to whastapp')}><img src={whatsapp} alt={'whatsapp'} /></button>
        <button className="rightBtn" onClick={() => handleChangePage('contact')}><img src={mail} alt={'contact'} /></button>
        <button className="rightBtn" onClick={() => handleChangePage('chat')}><img src={chat} alt={'chat'}/></button>
      </div>
      {menuActive &&
      <div className="menuContainer">
        <button className="menuBtn" onClick={() => handleChangePage('agence')}>
          L'agence
        </button>
        <button className="menuBtn" onClick={() => handleChangePage('evenementiel')}>
          événementiel
        </button>
        <button className="menuBtn" onClick={() => handleChangePage('films')}>
          films et contenus
        </button>
        <button className="menuBtn" onClick={() => handleChangePage('galerie')}>
          galerie
        </button>
        <button className="menuBtn" onClick={() => handleChangePage('references')}>
          références
        </button>
        <a href="https://www.digiplay.link" className="menuBtn">
          event app
        </a>
      </div>}
      {(video === 'video2' || showPage !== null) &&
      <button className={menuActive ? 'crossCta' : 'menuCta'} onClick={handleMenu}>
        <img src={menuActive ? cross : menu} alt={"Menu"} />
      </button>}
      {!showPage && <video
        id="video"
        ref={videoRef}
        autoPlay
        playsInline
        loop={videoLoop}
        muted={videoMuted}
        preload="metadata"
        // poster={`/images/posters/step${step}-min.png`}
        // style={{
        //   background:
        //     "transparent url(./images/posters/step" +
        //     step +
        //     "-min.png) 100% 100% / cover no-repeat",
        // }}
        onClick={() => console.log('click video')}
        onTimeUpdate={handleTimeUpdate}
        onEnded={handleEnded}
        onContextMenu={e => e.preventDefault()}
      >
        <source
          id="mp4"
          src={`/videos/${video}.mp4#t=0.001`}
          type="video/mp4"
        />
        <p>Your user agent does not support the HTML5 Video element.</p>
      </video>}
      <div className="fullContent">
        {showPage &&
        <><h1>{showPage}</h1>
        <button onClick={() => setShowPage(null)}>Fermer</button></>}
      </div>
    </div>
  )


  /* return (
    <div>
      <div className="bg">
        <div className="circle1">
          <img src={arc2} className={"arc1"} />
          <img src={arc2} className={"arc2"} />
          <img src={arc2} className={"arc3"} />
          <img src={arc2} className={"arc4"} />
          <img src={round3} className={"round3"} />
        </div>
      </div>
    </div>
  ); */
  /*return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );*/
}

export default App;
